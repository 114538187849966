 $(window).scroll(function(){
  if (jQuery(window).scrollTop() > 130) {
      jQuery('header').addClass('active');
  } else {
      jQuery('header').removeClass('active');
  }
});
$('.main-menu .dropdown').click(function() {
  $(this).parent().find('ul').animate({ height: 'toggle', opacity: 'toggle' }, 200);
  $(this).parent().find('ul').addClass('dropped');
});
$('.main-menu .dropdown').parent().find('svg').click(function() {
  $(this).parent().animate({ height: 'toggle', opacity: 'toggle' }, 200);
});
$('.mobile-menu .nav-item .dropdown').click(function() {
  $(this).toggleClass('active');
  $(this).parent().find('ul').toggleClass('open');
});
$('.personal-cabinet-desktop').click(function() {
  $('.cabinet-menu-desktop').animate({ height: 'toggle', opacity: 'toggle' }, 200);
});
$('.cabinet-menu-desktop .close').click(function() {
  $(this).parent().parent().hide();
});
$('.cabinet-menu-desktop').find('svg').click(function() {
  $(this).parent().parent().animate({ height: 'toggle', opacity: 'toggle' }, 200);
});
$('.main-menu .nav-item_search .nav-link').click(function() {
  $(this).parent().find('.search-form').animate({ opacity: 'toggle' }, 200);
});
$('.information svg').click(function() {
  $(this).parent().fadeTo( 500, 0 ).addClass('closed');
});
$('.hamburger-button').click(function(){
  $(this).toggleClass('open');
  $('.mobile-menu').toggleClass('open');
});

$('.right-advert-slider-wrap .close').click(function(){
  $('.right-advert-slider-wrap').addClass('closed');
});
$('.right-advert-slider-wrap .swipe-close').click(function(){
  $('.right-advert-slider-wrap').removeClass('closed');
});

$('.left-advert-slider-wrap .close').click(function(){
  $('.left-advert-slider-wrap').addClass('closed');
});
$('.left-advert-slider-wrap .swipe-close').click(function(){
  $('.left-advert-slider-wrap').removeClass('closed');
});


$('.personal-cabinet-mobile').click(function(){
  $(this).toggleClass('active');
  $('.personal-cabinet-mobile-menu').toggleClass('open');
});

$('.left-advert-slider').slick({
  infinite: true,
  slidesToShow: 6,
  vertical: true,
  verticalSwiping: true,
  prevArrow: $('.left-advert-slider-wrap .prev'),
  nextArrow: $('.left-advert-slider-wrap .next'),
});

$('.right-advert-slider').slick({
  infinite: true,
  slidesToShow: 1,
  prevArrow: $('.right-advert-slider-wrap .prev'),
  nextArrow: $('.right-advert-slider-wrap .next'),
  vertical: true,
});


$(function ($) {
  // var allJournal = $('.journal-item a');
  $('.filters-group').on('change','input[type="radio"]',function(){
      $(this).parent().parent().find('div').removeClass('active');
      $(this).parent().addClass('active');
      // if ($(this).attr('name')=="JournalSearch[language]"){
      //     var lang = this.value;
      //     console.log('change language '+this.id );
      //     $('.journal-item .cover img').toggle();
      //     $.each(allJournal, function(index, value) {
      //         console.log(value.href);
      //         value.href = value.href.substring(0, value.href.lastIndexOf('=')+1) + lang;
          });

});
$('.library .more').click(function(){
$(this).parent().find('.description').toggleClass('active');
});
$(function(){
$(".dummy-table-wrap").scroll(function(){
    $(".table-wrap")
        .scrollLeft($(".dummy-table-wrap").scrollLeft());
});
$(".table-wrap").scroll(function(){
    $(".dummy-table-wrap")
        .scrollLeft($(".table-wrap").scrollLeft());
});
});
$('.replie-item svg').click(function(){
$(this).toggleClass('active');
$(this).parent().parent().find('.text').toggleClass('active');
});
$('.replie-item .read-more').click(function(){
$(this).toggleClass('active');
if($(this).hasClass('active')) {
  $(this).text("Згорнути відгук");
}
else {
  $(this).text("Дивитися більше");
}
$(this).parent().parent().find('.text').toggleClass('active');
});




jQuery(function($){
	$(document).mouseup(function (e){
		var div = $(".dropped");
		if (!div.is(e.target)
		    && div.has(e.target).length === 0) {
			div.hide();
		}
	});
});
